import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'RootTypes';

interface ExportState {
  currentOperationDetailUrl: string;
}

const initialState: ExportState = {
  currentOperationDetailUrl: null
};
export const assetExportSlice = createSlice({
  initialState,
  name: 'assetExport',
  reducers: {
    exportFinished: (draft) => {
      draft.currentOperationDetailUrl = null;
    },
    exportRequested: (draft, action: PayloadAction<string>) => {
      draft.currentOperationDetailUrl = action.payload;
    }
  }
});

export const { exportFinished, exportRequested } = assetExportSlice.actions;

export const selectCurrentOperationUrl = (state: RootState) =>
  state.assets.assets.export.currentOperationDetailUrl;

export interface ExportOperationDetail {
  createdAt: string;
  data: { fileName: string };
  durationInSec: number;
  id: string;
  phase: string;
  phaseProgress: number;
  progress: number;
  state: 'Completed' | 'Enqueued' | 'Failed' | 'Inprogress';
  succeededAt: string;
  type: 'AssetExport';
  userId: string;
}

export default assetExportSlice.reducer;
