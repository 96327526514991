import { AppThunk } from 'RootTypes';
import { FileInfo, FileProgressCheckResponse } from './types';
import { apiCaller } from 'store/common/apiCaller';
import { apiUrls } from 'api';
import {
  assetImportCleared,
  branchIdUpdated,
  fileInfoUpdated,
  fileUploadSchemaErrorsUpdated,
  importProcessCleared,
  mainCategoryIdUpdated,
  summaryUpdated,
  uploadAgainTriggered,
  uploadStarted
} from './slice';
import { selectBranchId, selectMainCategoryId } from './selectors';
import axios from 'axios';

export const getTemplate =
  (branchId: string, mainCategoryId: string): AppThunk<Promise<string>> =>
  async (dispatch) => {
    dispatch(branchIdUpdated(branchId));
    dispatch(mainCategoryIdUpdated(mainCategoryId));

    const requestBuilder = () =>
      axios.get(apiUrls.assetImport.getAssetImportTemplate(branchId, mainCategoryId), {
        responseType: 'arraybuffer'
      });
    const data = await dispatch(apiCaller(requestBuilder));
    const blob = new Blob([data]);
    const template = URL.createObjectURL(blob);

    return template;
  };

export const uploadFile =
  (file: File): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const branchId = selectBranchId(state);
    const mainCategoryId = selectMainCategoryId(state);
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    const requestBuilder = () =>
      axios.post(apiUrls.assetImport.assetUploadFile(branchId, mainCategoryId), formData, config);

    let selectedFile: FileInfo;
    let schemaErrors: string[];
    try {
      selectedFile = {
        extension: file.name.split('.').pop(),
        name: file.name,
        size: file.size
      };
      await dispatch(apiCaller(requestBuilder, { autoHandleExceptions: [400] }));
    } catch (error: any) {
      const details = JSON.parse(error.response.data.detail);
      schemaErrors = Object.values(details);
    }

    dispatch(fileInfoUpdated(selectedFile));
    dispatch(fileUploadSchemaErrorsUpdated(schemaErrors));
    dispatch(getImportSummary());
  };

export const getUploadedFile = (): AppThunk<Promise<string>> => async (dispatch) => {
  const requestBuilder = () =>
    axios.get(apiUrls.assetImport.getUploadedFile(), {
      responseType: 'arraybuffer'
    });
  const data = await dispatch(apiCaller(requestBuilder));
  const blob = new Blob([data]);
  const file = URL.createObjectURL(blob);

  return file;
};

export const getValidationErrorFile = (): AppThunk<Promise<string>> => async (dispatch) => {
  const requestBuilder = () =>
    axios.get(apiUrls.assetImport.getValidationErrorFile(), {
      responseType: 'arraybuffer'
    });
  const data = await dispatch(apiCaller(requestBuilder));
  const blob = new Blob([data]);
  const file = URL.createObjectURL(blob);

  return file;
};

export const getImportSummary =
  (): AppThunk<Promise<FileProgressCheckResponse>> => async (dispatch) => {
    const requestBuilder = () =>
      axios.get<FileProgressCheckResponse>(apiUrls.assetImport.getImportSummary());

    try {
      const response = await dispatch(apiCaller(requestBuilder, { autoHandleExceptions: [404] }));
      dispatch(summaryUpdated(response));
      return response;
    } catch (error) {
      dispatch(importProcessCleared());
      return null;
    }
  };

export const cancelFileProgress = (): AppThunk<Promise<void>> => async (dispatch) => {
  const requestBuilder = () => axios.put(apiUrls.assetImport.cancelFileProgress());

  await dispatch(apiCaller(requestBuilder));
  dispatch(getImportSummary());
};

export const startImportAssets = (): AppThunk<Promise<void>> => async (dispatch) => {
  const requestBuilder = () => axios.put(apiUrls.assetImport.startImportAssets());

  await dispatch(apiCaller(requestBuilder));
  dispatch(uploadStarted(true));
};

export const completeImportProcess =
  (options?: { uploadAgain?: boolean }): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const { uploadAgain } = options ?? { uploadAgain: false };
    const requestBuilder = () => axios.put(apiUrls.assetImport.completeImportProcess());

    await dispatch(apiCaller(requestBuilder));
    if (uploadAgain) {
      dispatch(uploadAgainTriggered());
    } else {
      dispatch(assetImportCleared());
    }
  };

export const getImportedErrorFile = (): AppThunk<Promise<string>> => async (dispatch) => {
  const requestBuilder = () =>
    axios.get(apiUrls.assetImport.getImportedErrorFile(), {
      responseType: 'arraybuffer'
    });
  const data = await dispatch(apiCaller(requestBuilder));
  const blob = new Blob([data]);
  const template = URL.createObjectURL(blob);

  return template;
};
