import { NotificationFilter } from '../filter/notificationFilter';
import { PagedRequestOptions } from 'store/common';

export interface NotificationListItem {
  branchIds?: string[];
  content?: string;
  createdDate?: string;
  id?: string;
  isRead?: boolean;
  title?: string;
  webLink?: string;
}

export interface Notification {
  branchIds?: string[];
  id?: string;
  parameters?: NotificationParameter[];
  type?: string;
}

export const arrayFields = ['AssetIds'] as const;
type ArrayParameterKeys = (typeof arrayFields)[number];

export type ArrayParameters = Partial<Record<ArrayParameterKeys, string[]>>;

const stringFields = [
  'AssetCustodyDenyReason',
  'MainCategoryId',
  'NewBranch',
  'NotificationId',
  'OldBranch',
  'OccurredOn',
  'PlanTitle',
  'PlanType',
  'TriggeredByUserName'
] as const;
type StringParameterKeys = (typeof stringFields)[number];
export type StringParameters = Partial<Record<StringParameterKeys, string>>;

export type Parameters = ArrayParameters & StringParameters;

export interface NotificationParameter {
  key: keyof Parameters;
  value: string;
}

export interface UnreadNotificationResponse {
  unreadNotificationCount: number;
}

export type PaginatedNotificationRequest = PagedRequestOptions & NotificationFilter;
