import {
  InfiniteData,
  QueryCache,
  QueryClient,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult
} from '@tanstack/react-query';
import { showSnackbarMessage } from 'utils/snackbar';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type PagedResult<T> = {
  currentPage?: number;
  items: T[];
  page?: number;
  size: number;
  total: number;
};

export type QueryResult =
  | UseMutationResult<any, any, any, any>
  | UseQueryResult<any, any>
  | UseInfiniteQueryResult<any, any>;

type ErrorStatusCodes = 400 | 401 | 404 | 500;

const isLocal = import.meta.env.MODE === 'development';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => showSnackbarError(error)
    },
    queries: {
      refetchOnWindowFocus: !isLocal,
      retry: 0
    }
  },
  queryCache: new QueryCache({
    onError: (error) => showSnackbarError(error)
  })
});

export const getNextPageParam = <T>(lastPage: PagedResult<T>) => {
  const { total } = lastPage;
  const lastPageNumber = lastPage.currentPage || lastPage.page;
  const loadedTotal = lastPageNumber * lastPage.size;

  return total > loadedTotal ? lastPageNumber + 1 : undefined;
};

function useDistinctItems<T>(items: T[], isSuccess: boolean, id: keyof T) {
  const distinctItems = useMemo(() => {
    if (!isSuccess) {
      return [];
    }

    return [...new Map(items.map((item) => [item[id], item])).values()];
  }, [items, id, isSuccess]);

  return distinctItems;
}

export function useInfiniteQueryItems<T>(
  data: InfiniteData<PagedResult<T>>,
  isSuccess: boolean,
  id: keyof T
) {
  const dataArray = data?.pages.flatMap((i) => i.items) ?? [];
  const items = useDistinctItems(dataArray, isSuccess, id);

  const total = isSuccess ? data.pages[0].total : 0;

  return { items, total };
}

export function useQueryItems<T>(data: T[], isSuccess: boolean, id: keyof T) {
  const items = useDistinctItems(data, isSuccess, id);
  const total = isSuccess ? data.length : 0;

  return { items, total };
}

export function usePagedQueryItems<T>(data: PagedResult<T>, isSuccess: boolean, id: keyof T) {
  const items = useDistinctItems(data?.items ?? [], isSuccess, id);
  const total = isSuccess ? data.total : 0;

  return { items, total };
}

export function useConditionalNavigate(condition: boolean, path: string) {
  const navigate = useNavigate();

  useEffect(() => {
    if (condition) {
      navigate(path);
    }
  }, [condition, navigate, path]);
}

export const showSnackbarError = async (error: any, autoHandleExceptions?: ErrorStatusCodes[]) => {
  if (!autoHandleExceptions?.includes(error?.response?.status)) {
    const errorMessage = await getErrorMessageAsync(error);

    showSnackbarMessage(errorMessage, 'error');
  }
};

export const getErrorMessageAsync = async (error: any) => {
  // TODO: Refactor these code with axios v1 upgrade
  if (!error?.response) {
    return error?.message ?? 'Unexpected Error';
  }

  if (!error.response.data) {
    if (error.response.statusText) {
      return error.response.statusText;
    }

    return error.message;
  }

  let { data } = error.response;
  if (error.response.data instanceof Blob) {
    data = JSON.parse(await (error.response.data as Blob).text());
  }

  let message: string;
  const { detail, title } = data;
  try {
    const details = JSON.parse(detail);
    const messages = Object.values(details)[0] as string[];
    [message] = messages;
  } catch (innerError) {
    if (detail?.length > 0) {
      // Lokasyon Serviste detail JSON gelmiyor. Orası düzeltilince kaldırabiliriz.
      message = detail;
    } else {
      message = title ?? error.response.statusText;
    }
  }

  return message;
};
