const listingUrls = {
  getAssetsByCategoryBrandModelId: (categoryTaskId: string) =>
    `/api/listing/assets/by-categorybrandmodel/${categoryTaskId}`
};

const assetListUrls = {
  getAssetList: () => `/api/listing/assets/filter/selectable-columns`,
  getAssetsByAssetIds: () => `/api/listing/assets/search/by-ids`,
  getCustodyAssignmentRequests: () => '/api/listing/assets/custody-assignments',
  getMyAssetsList: () => `/api/listing/my-assets/filter/selectable-columns`
};

const assetListFilterUrls = {
  deleteSavedFilter: (filterId: string) => `/api/listing/filters/${filterId}`,
  getAssetListSavedFilters: () => `/api/listing/filters`,
  getAssetValuesForBulkEdit: () => '/api/listing/assets/asset-values-for-bulk-edit',
  saveFilter: () => `/api/listing/filters`
};

const asyncOperationsUrls = {
  cancelAsyncOperationById: (id: string) => `/api/listing/async-operations/${id}`,
  downloadAsyncExportedAssetFile: (fileName: string) =>
    `/api/listing/assets/export-async/download/${fileName}`,
  getAsyncOperationDetailById: (id: string) => `/api/listing/async-operations/${id}`
};

const listActionsUrls = {
  getAssetActionsValidation: () => `/api/listing/assets/check-assets-actions`,
  getAssetListLocationSignalErrors: () => `/api/listing/assets/filter/location-signal-errors`,
  getMyAssetListLocationSignalErrors: () => `/api/listing/my-assets/filter/location-signal-errors`
};

export default {
  ...asyncOperationsUrls,
  ...listingUrls,
  ...assetListUrls,
  ...assetListFilterUrls,
  ...listActionsUrls
};
