import {
  ASSETLIST,
  ASSET_DETAIL,
  BULK_UPDATED_ASSETS_DETAIL,
  CUSTODY_APPROVED_ASSETS_DETAIL,
  CUSTODY_DENIED_ASSETS_DETAIL,
  CUSTODY_REQUEST_DETAILL,
  MULTI_CUSTODY_ASSIGNED_ASSETS_DETAIL,
  MULTI_CUSTODY_REMOVED_ASSETS_DETAIL,
  PASSIVE_ASSETS_DETAIL,
  TRANSFER_ASSETS_DETAIL
} from 'routes/constant-route';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { RouteGuard } from 'components/RouteGuards/AuthGuard';
import { SuspenseOutlet, delayedLazy } from '../helpers';
import { User, isUserAuthorized } from 'store/slices/session';

const AssetDetail = delayedLazy(() => import('views/Asset/Assets/AssetDetail/AssetDetail'));
const AssetBulkEdit = delayedLazy(() => import('views/Asset/Assets/BulkEdit/BulkEdit'));
const AssetAdd = delayedLazy(() => import('views/Asset/Assets/AssetDetail/AssetAdd'));
const AssetList = delayedLazy(() => import('views/Asset/Assets/AssetList/List/Assets'));
const MyAssetsList = delayedLazy(() => import('views/Asset/MyAssets/MyAssets'));
const PassiveCustodyNotificationDetail = delayedLazy(
  () => import('views/Asset/MyAssets/PassiveMyAsset/PassiveMyAssetsNotificationDetail')
);
const TransferCustodyNotificationDetail = delayedLazy(
  () => import('views/Asset/MyAssets/TransferMyAsset/TransferMyAssetsNotificationDetail')
);
const TransferNotificationDetail = delayedLazy(
  () => import('views/Asset/Assets/TransferNotification/NotificationDetail')
);
const BulkUpdatedAssetsNotificationDetail = delayedLazy(
  () => import('views/Asset/Assets/BulkAssetUpdated/BulkAssetUpdatedNotificationDetail')
);
const CustodyAssignedNotificationDetail = delayedLazy(
  () => import('views/Asset/MyAssets/MultiCustodyAssigned/CustodyAssignedNotificationDetail')
);
const CustodyRemovedNotificationDetail = delayedLazy(
  () => import('views/Asset/MyAssets/MultiCustodyRemoved/CustodyRemovedNotificationDetail')
);
const CustodyAssignmentApprovedDetail = delayedLazy(
  () => import('views/Asset/MyAssets/CustodyApproved/CustodyAssignmentApprovedDetail')
);
const CustodyAssignmentDeniedDetail = delayedLazy(
  () => import('views/Asset/MyAssets/CustodyDenied/CustodyAssignmentDeniedDetail')
);

const CustodyAssignmentRequestDetail = delayedLazy(
  () => import('views/Asset/MyAssets/CustodyAssignmentRequest/CustodyAssignmentRequestDetail')
);

export default function renderAssetRoutes(sessionUser: User) {
  const isAuthorizedForAssetList = isUserAuthorized(sessionUser, 'assetList');

  return (
    <Route element={<SuspenseOutlet />} path="asset">
      <Route element={<Outlet />} path="assets">
        <Route
          element={
            <RouteGuard authKey="assetList">
              <AssetList />
            </RouteGuard>
          }
          path="list"
        >
          <Route element={<TransferNotificationDetail />} path={TRANSFER_ASSETS_DETAIL} />
          <Route
            element={<BulkUpdatedAssetsNotificationDetail />}
            path={BULK_UPDATED_ASSETS_DETAIL}
          />
        </Route>
        <Route
          element={
            <RouteGuard authKey="assetList">
              <AssetAdd
                getCancelNavigationAddress={() => ASSETLIST}
                getSaveNavigationAddress={(id: string) => `${ASSET_DETAIL.replace(':id', id)}`}
              />
            </RouteGuard>
          }
          path="add"
        />
        <Route element={<AssetDetail />} path=":id" />
        <Route element={<AssetBulkEdit />} path="bulk-edit" />
        <Route element={<Navigate replace to="list" />} index />
      </Route>
      <Route element={<Outlet />} path="my-assets">
        <Route
          element={
            <RouteGuard>
              <MyAssetsList />
            </RouteGuard>
          }
          path="list"
        >
          <Route element={<PassiveCustodyNotificationDetail />} path={PASSIVE_ASSETS_DETAIL} />
          <Route element={<TransferCustodyNotificationDetail />} path={TRANSFER_ASSETS_DETAIL} />
          <Route
            element={<CustodyAssignedNotificationDetail />}
            path={MULTI_CUSTODY_ASSIGNED_ASSETS_DETAIL}
          />
          <Route
            element={<CustodyRemovedNotificationDetail />}
            path={MULTI_CUSTODY_REMOVED_ASSETS_DETAIL}
          />
          <Route
            element={<CustodyAssignmentApprovedDetail />}
            path={CUSTODY_APPROVED_ASSETS_DETAIL}
          />
          <Route element={<CustodyAssignmentDeniedDetail />} path={CUSTODY_DENIED_ASSETS_DETAIL} />
          <Route element={<CustodyAssignmentRequestDetail />} path={CUSTODY_REQUEST_DETAILL} />
        </Route>
        <Route element={<AssetDetail />} path=":id" />
        <Route element={<Navigate replace to="list" />} index />
      </Route>
      {isAuthorizedForAssetList ? (
        <Route element={<Navigate replace to="assets" />} index />
      ) : (
        <Route element={<Navigate replace to="my-assets" />} index />
      )}
    </Route>
  );
}
